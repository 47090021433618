import React from 'react';

const XIcon = () => {
	return (
		<svg width={'19px'} height={'17px'} viewBox={'0 0 19 17'} version={'1.1'} xmlns={'http://www.w3.org/2000/svg'} xmlnsXlink={'http://www.w3.org/1999/xlink'}>
			<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
				<g transform={'translate(-39.000000, -190.000000)'}>
					<g id={'Group-10'} transform={'translate(20.000000, 170.000000)'}>
						<polygon id={'Fill-1'} fill={'#FFFFFF'} points={'27.485 20 20.414 27.071 19 28.485 27.485 36.97 28.899 35.557 22.827 29.485 37.034 29.485 37.034 27.485 22.827 27.485 28.899 21.414'} />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default XIcon;
