import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import { slugify } from '../functions';

import SEO from '../components/SEO';
import Link from '../components/Link';
import Button from '../components/Button';
import Image from '../components/Image';
import RenderStaticHTML from '../components/RenderStaticHTML';
import Arrow from '../icons/arrow';

const nsBase = 'page';
const ns = `${ nsBase }-insights-detail`;

const InsightsDetailPage = ({ pageContext }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		article,
		articleBefore,
		articleAfter,
		relatedArticles,
		mobileView,
	} = pageContext;

	const [shareUrl, setShareUrl] = useState('');

	const insightView = article => {
		const articleImage = article.image;
		const subtitle = article.author?.firstName;

		return (
			<div className={`${ ns }__related-article`} key={article.handle}>
				<div className={`${ ns }__related-article-image`}>
					<Link to={`/${ mobileView ? 'mobile-' : '' }insights/${ article.handle }`}>
						<Image {...articleImage} dataMedia />
					</Link>
				</div>
				<div className={`${ ns }__related-article-content`}>
					<div className={`${ ns }__related-article-eyebrow`}>
						{subtitle && (
							<div className={`${ ns }__related-article-subtitle`}>
								{`By ${ subtitle }`}
							</div>
						)}
						<div className={`${ ns }__related-article-date`}>
							{article.publishedAt}
						</div>
					</div>
					<div className={`${ ns }__related-article-title`}>
						<Link to={`/${ mobileView ? 'mobile-' : '' }insights/${ article.handle }`}>
							{article.title}
						</Link>
					</div>
					<div className={`${ ns }__related-article-footer`}>
						<div className={`${ ns }__related-article-categories`}>
							{`Posted in `}
							{article.tags.map(articleTag => {
								if (articleTag.includes('category::')) {
									return (
										<div className={`${ ns }__related-article-category`} key={articleTag}>
											<Link to={`/${ mobileView ? 'mobile-' : '' }insights/?category=${ slugify(articleTag.split('::')[1]) }`}>
												{articleTag.split('::')[1]}
											</Link>
										</div>
									);
								}

								return null;
							})}
						</div>
						<div className={`${ ns }__related-article-read-more`}>
							<Link to={`/insights/${ article.handle }`}>
								Read more
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		setShareUrl(window.location);
	}, []);

	return (
		<div className={rootClassnames}>
			{mobileView && (
				<Helmet>
					<body data-mobile-view />
				</Helmet>
			)}
			<SEO
				title={article.title}
				description={article.excerpt}
				image={article.image.src}
			/>
			<div className={`back-button`}>
				<Button to={'/mobile-insights'}>
					<Arrow />
				</Button>
			</div>
			<div className={`${ ns }__header`}>
				<div className={`container narrow`}>
					<h1 className={`${ ns }__title`}>
						{article.title}
					</h1>
					<div className={`${ ns }__author-date`}>
						{article.author && (
							<div className={`${ ns }__author`}>
								{`By ${ article.author?.firstName }`}
							</div>
						)}
						<div className={`${ ns }__date`}>
							{article.publishedAt}
						</div>
					</div>
					{!mobileView && (
						<div className={`${ ns }__categories`}>
							{`Posted in `}
							{article.tags.map(articleTag => {
								if (articleTag.includes('category::')) {
									return (
										<div className={`${ ns }__category`} key={articleTag}>
											<Link to={`/insights/?category=${ slugify(articleTag.split('::')[1]) }`}>
												{articleTag.split('::')[1]}
											</Link>
										</div>
									);
								}

								return null;
							})}
						</div>
					)}
				</div>
			</div>
			{article.image && (
				<div className={`container`}>
					<div className={`${ ns }__image`}>
						<Image {...article.image} dataMedia />
					</div>
				</div>
			)}
			<div className={`${ ns }__content`}>
				<div className={`container narrow`}>
					<RenderStaticHTML html={article.contentHtml} />
				</div>
			</div>
			{!mobileView && (
				<div className={`${ ns }__footer`}>
					<div className={`container-fluid`}>
						<div className={`${ ns }__footer-content`}>
							<div className={`${ ns }__previous-article`}>
								{articleBefore && (
									<Link to={`/insights/${ articleBefore.handle }`}>
										Previous
									</Link>
								)}
							</div>
							<div className={`${ ns }__next-article`}>
								{articleAfter && (
									<Link to={`/insights/${ articleAfter.handle }`}>
										Next
									</Link>
								)}
							</div>
							<div className={`${ ns }__share`}>
								{`Share `}
								<Link target={'blank'} href={`https://www.facebook.com/sharer/sharer.php?u=${ shareUrl }`}>Facebook</Link>
								<Link target={'blank'} href={`https://twitter.com/intent/tweet?url=${ shareUrl }`}>Twitter</Link>
							</div>
						</div>
					</div>
				</div>
			)}
			{relatedArticles && relatedArticles.length > 0 && (
				<div className={`${ ns }__related-articles`}>
					<div className={`container-fluid`}>
						<div className={`${ ns }__related-articles-container`}>
							{relatedArticles.map(article => {
								return insightView(article);
							})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default InsightsDetailPage;
